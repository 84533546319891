const NOT_UPLOADING = 'scratch-gui/project-upload-state/NOT_UPLOADING';
const STARTED_UPLOAD_TO_SERVER = 'scratch-gui/project-upload-state/STARTED_UPLOAD_TO_SERVER';
const PREPARING_UPLOAD_TO_SERVER = 'scratch-gui/project-upload-state/PREPARING_UPLOAD_TO_SERVER';
const UPLOADING_T0_SERVER = 'scratch-gui/project-upload-state/UPLOADING_T0_SERVER';
const DONE_UPLOAD_TO_SERVER = 'scratch-gui/project-upload-state/DONE_UPLOAD_TO_SERVER';
const ERROR_UPLOAD_TO_SERVER = 'scratch-gui/project-upload-state/ERROR_UPLOAD_TO_SERVER';
const UPLOAD_STATE_CHANGED = 'scratch-gui/project-upload-state/UPLOAD_STATE_CHANGED';

const UploadingStates = {
    NOT_UPLOADING,
    STARTED_UPLOAD_TO_SERVER,
    PREPARING_UPLOAD_TO_SERVER,
    UPLOADING_T0_SERVER,
    DONE_UPLOAD_TO_SERVER,
    ERROR_UPLOAD_TO_SERVER
}

const getIsUploading = state => {
    return state.uploadingState !== NOT_UPLOADING;
};

const getUploadState = state => {
    switch (state.uploadingState){
    case STARTED_UPLOAD_TO_SERVER: return 0;
    case PREPARING_UPLOAD_TO_SERVER: return 1;
    case UPLOADING_T0_SERVER: return 2;
    default: return 2;
    }
};

const initialState = {
    uploadingState: NOT_UPLOADING
};

const reducer = function (state, action) {    
    if (typeof state === 'undefined') state = initialState;
    if (action.type === UPLOAD_STATE_CHANGED){
        return {
            ...state,
            uploadingState: action.nextState
        };
    }
    return state;
    
    
};

const onUploadStatusChanged = state => ({
    type: UPLOAD_STATE_CHANGED,
    nextState: state
});


export {
    reducer as default,
    initialState as projectUploadStateInitialState,
    getIsUploading,
    getUploadState,
    onUploadStatusChanged as onUploadStateChanged,
    UploadingStates
};
