/**
 * Created by ADMIN on 19/5/9.
 */
const GET_ACCOUNT = 'scratch-gui/Account/GET_ACCOUNT';
const SET_ACCOUNT = 'scratch-gui/Account/SET_ACCOUNT';
const DEL_ACCOUNT = 'scratch-gui/Account/DEL_ACCOUNT';
const initialState = {
    account: {},
    accountState: false // true:已登录,false:未登录
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_ACCOUNT:{
            return Object.assign({}, state, {
                account: action.account,
                accountState: action.account && Object.keys(action.account).length ? true : false
            });
        }
        case DEL_ACCOUNT: {
            return { 
                account: {},
                accountState: false
            }
        }
        case GET_ACCOUNT:
        default:
            return state;
    }
};

const getAccount = function () {
    return {
        type: GET_ACCOUNT
    };
};

const setAccount = function (account) {
    return {
        type: SET_ACCOUNT,
        account: account
    };
};

const delAccount =  function () {
    return {
        type: DEL_ACCOUNT
    };
};

export {
    reducer as default,
    initialState as accountInitialState,
    getAccount,
    setAccount,
    delAccount
};
