import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import LoginModalComponent from '../../components/my/login-modal.jsx';
import {getQueryString, encode64} from '../../lib/request';
import request from '../../lib/request';
import {closeLoginModal} from '../../reducers/modals';
import {setConfirm, setConfirmBack} from '../../reducers/confirm';
import Cookies from 'universal-cookie';
import {connect} from 'react-redux';
import { setAccount } from '../../reducers/account';
import {
    closeAccountMenu
} from '../../reducers/menus';
const cookies = new Cookies();
import GlobalConfig from '../../config';

class LoginModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'componentDidMount',
            'handleOnSave',
            'onHandleCancel'
        ]);
        this.state = {

        };
    }

    componentDidMount (){

    }

    handleOnSave ({phone, password}){
        // console.log(data);
        if (!phone || !password){
            alert('请先完善信息~');
            return;
        }
        const reqData = {
            platform: 'mayuan',
            username: phone,
            password: encode64(password)
        };
        request.default_request(request.POST, JSON.stringify(reqData), '/api/login', result => {
            const msg = {
                type: 1,
                message: result.code == 0 ? '登录成功' : result.msg,
                status: result.code == 0 ? 1 : 2,
                timeout: 2000,
                show: true
            };
            this.props.setConfirm(msg);
            if (result.code == 0){
                const token = result.result.token;
                const userInfo = result.result.userMsg.userInfo;
                this.props.setAccount(userInfo);
                // 设置token
                const d = new Date();
                d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
                cookies.set('token', token, {expires: d, path: '/', domain: GlobalConfig.domain});
                this.props.closeLoginModal();
                // window.location.reload();
            }
        }, '//'+GlobalConfig.user_service_url, 'application/json');
    }

    onHandleCancel () {
        this.props.closeLoginModal();
    }
    render () {
        /* eslint-disable no-unused-vars */
        return (
            <LoginModalComponent
                handleCancel={this.onHandleCancel}
                handleOnSave={this.handleOnSave}
                title="登录"
            />);
    }
}

LoginModal.propTypes = {
    closeLoginModal: PropTypes.func
};

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => ({
    closeLoginModal: () => {
        dispatch(closeLoginModal());
        dispatch(closeAccountMenu());
    },
    setConfirm: confirm => {
        dispatch(setConfirm(confirm));
    },
    setAccount: account => dispatch(setAccount(account))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginModal);
