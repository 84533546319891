import classNames from 'classnames';
import {connect} from 'react-redux';
import {defineMessages, FormattedMessage, injectIntl, intlShape} from 'react-intl';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import React from 'react';

import Box from '../box/box.jsx';
import Button from '../button/button.jsx';
import {ComingSoonTooltip} from '../coming-soon/coming-soon.jsx';
import Divider from '../divider/divider.jsx';
import LanguageSelector from '../../containers/language-selector.jsx';
import SBFileUploader from '../../containers/sb-file-uploader.jsx';
import MYFileUploader from '../../containers/my-file-uploader.jsx';
import MenuBarMenu from './menu-bar-menu.jsx';
import ProjectLoader from '../../containers/project-loader.jsx';
import SimpleLoader from '../../containers/simple-fileloader.jsx';
import Menu from '../../containers/menu.jsx';
import {MenuItem, MenuSection} from '../menu/menu.jsx';
import ProjectTitleInput from './project-title-input.jsx';

import SB3Downloader from '../../containers/sb3-downloader.jsx';
import DeletionRestorer from '../../containers/deletion-restorer.jsx';
import TurboMode from '../../containers/turbo-mode.jsx';
import ProjectSaveContainer from '../../models/project-save/container.jsx';
import AccountContainer from '../../models/account/container.jsx';

import {openProjectModals, openTipsLibrary, openWorkLibrary} from '../../reducers/modals';
import {setPlayer} from '../../reducers/mode';
import {setProject} from '../../reducers/project-info';

import Cookies from 'universal-cookie';
import {
    getIsUpdating,
    getIsShowingProject,
    requestNewProject,
    remixProject,
    saveProjectAsCopy
} from '../../reducers/project-state';
import {
    openAccountMenu,
    closeAccountMenu,
    accountMenuOpen,
    openFileMenu,
    closeFileMenu,
    fileMenuOpen,
    openEditMenu,
    closeEditMenu,
    editMenuOpen,
    openLanguageMenu,
    closeLanguageMenu,
    languageMenuOpen,
    openLoginMenu,
    closeLoginMenu,
    loginMenuOpen
} from '../../reducers/menus';

import styles from './menu-bar.css';

import mystuffIcon from './icon--mystuff.png';
import remixIcon from './icon--remix.svg';
import dropdownCaret from './dropdown-caret.svg';
import languageIcon from '../language-selector/language-icon.svg';

import scratchLogo from './logo.png';


import backIcon from './icon--back.svg';
import {getHost, getQueryString} from '../../lib/request';
import SaveProgress from '../../containers/my/save-progress.jsx';
import {getUploadState, getIsUploading, onUploadStateChanged} from '../../reducers/project-upload_state';

const host = getHost();
const cookies = new Cookies();

const newWork = function (){
    const r = confirm('离开前请确定作品已经保存');
    if (r === true) {
        // window.location.reload();
        let url = host;
        let platFormId = getQueryString('platFormId');
        if(platFormId){
            url = `${url}?platFormId=${platFormId}`;
        }else{
            url = window.location.href
            url = url.substr(0,url.indexOf("?id="))
        }

        window.location.href = url;
    } else {
        return;
    }
};

const ariaMessages = defineMessages({
    language: {
        id: 'gui.menuBar.LanguageSelector',
        defaultMessage: 'language selector',
        description: 'accessibility text for the language selection menu'
    },
    tutorials: {
        id: 'gui.menuBar.tutorialsLibrary',
        defaultMessage: 'Tutorials',
        description: 'accessibility text for the tutorials button'
    }
});

const MenuBarItemTooltip = ({
    children,
    className,
    enable,
    id,
    place = 'bottom'
}) => {
    if (enable) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
    return (
        <ComingSoonTooltip
            className={classNames(styles.comingSoon, className)}
            place={place}
            tooltipClassName={styles.comingSoonTooltip}
            tooltipId={id}
        >
            {children}
        </ComingSoonTooltip>
    );
};


MenuBarItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    enable: PropTypes.bool,
    id: PropTypes.string,
    place: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
};

const MenuItemTooltip = ({id, isRtl, children, className}) => (
    <ComingSoonTooltip
        className={classNames(styles.comingSoon, className)}
        isRtl={isRtl}
        place={isRtl ? 'left' : 'right'}
        tooltipClassName={styles.comingSoonTooltip}
        tooltipId={id}
    >
        {children}
    </ComingSoonTooltip>
);

MenuItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string,
    isRtl: PropTypes.bool
};

class MenuBar extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClickNew',
            'handleClickRemix',
            'handleClickSave',
            'handleClickSaveAsCopy',
            'handleCloseFileMenuAndThen',
            'handleLanguageMouseUp',
            'handleRestoreOption',
            'restoreOptionMessage',
            'handleProjectTitleChanged'
        ]);
    }
    componentDidUpdate (prevProps) {
        // if we're no longer showing the project (loading, or whatever), close menus
        if (this.props.isShowingProject && !prevProps.isShowingProject) {
            this.props.onRequestCloseFile();
            this.props.onRequestCloseEdit();
        }
    }

    handleClickNew () {
        const r = confirm('作品已经保存了吗?');
        if (r === true) {
            // window.location.reload();
            const url = '/';
            window.location.href = url;
        } else {
            return;
        }
    }
    handleClickRemix () {
        this.props.onClickRemix();
    }
    handleClickSave () {

        this.props.onClickSave();
    }
    handleClickSaveAsCopy () {
        this.props.onClickSaveAsCopy();
    }
    handleRestoreOption (restoreFun) {
        return () => {
            restoreFun();
            this.props.onRequestCloseEdit();
        };
    }
    handleCloseFileMenuAndThen (fn) {
        return () => {
            this.props.onRequestCloseFile();
            fn();
        };
    }
    handleLanguageMouseUp (e) {
        if (!this.props.languageMenuOpen) {
            this.props.onClickLanguage(e);
        }
    }
    restoreOptionMessage (deletedItem) {
        switch (deletedItem) {
        case 'Sprite':
            return (<FormattedMessage
                defaultMessage="Restore Sprite"
                description="Menu bar item for restoring the last deleted sprite."
                id="gui.menuBar.restoreSprite"
            />);
        case 'Sound':
            return (<FormattedMessage
                defaultMessage="Restore Sound"
                description="Menu bar item for restoring the last deleted sound."
                id="gui.menuBar.restoreSound"
            />);
        case 'Costume':
            return (<FormattedMessage
                defaultMessage="Restore Costume"
                description="Menu bar item for restoring the last deleted costume."
                id="gui.menuBar.restoreCostume"
            />);
        default: {
            return (<FormattedMessage
                defaultMessage="Restore"
                description="Menu bar item for restoring the last deleted item in its disabled state." /* eslint-disable-line max-len */
                id="gui.menuBar.restore"
            />);
        }
        }
    }

    handleProjectTitleChanged (title){
        this.props.setProject(
            {
                ...this.props.projectInfo,
                name: title
            }
        )
    }
    render () {
        const saveNowMessage = (
            <FormattedMessage
                defaultMessage="Save now"
                description="Menu bar item for saving now"
                id="gui.menuBar.saveNow"
            />
        );
        const createCopyMessage = (
            <FormattedMessage
                defaultMessage="Save as a copy"
                description="Menu bar item for saving as a copy"
                id="gui.menuBar.saveAsCopy"
            />
        );
        const remixMessage = (
            <FormattedMessage
                defaultMessage="Remix"
                description="Menu bar item for remixing"
                id="gui.menuBar.remix"
            />
        );
        const newProjectMessage = (
            <FormattedMessage
                defaultMessage="New"
                description="Menu bar item for creating a new project"
                id="gui.menuBar.new"
            />
        );


        const remixButton = (
            <Button
                className={classNames(
                    styles.menuBarButton,
                    styles.remixButton
                )}
                iconClassName={styles.remixButtonIcon}
                iconSrc={remixIcon}
                onClick={this.handleClickRemix}
            >
                {remixMessage}
            </Button>
        );
        const {
            canRemix,
            uploadState,
            accountState,
            isRtl,
            isUpdating,
            isUploading,
            fileMenuOpen,
            editMenuOpen,
            onClickFile,
            onClickEdit,
            onRequestCloseFile,
            onUpdateProjectTitle,
            onRequestCloseEdit,
            onOpenWorkLibrary
        } = this.props;
        return (
            <Box
                className={classNames(
                    this.props.className,
                    styles.menuBar,
                    {[styles.saveInProgress]: isUpdating}
                )}
            >
                <div className={styles.mainMenu}>
                    <div className={styles.fileGroup}>
                        <div className={classNames(styles.menuBarItem)}>
                            <a
                                href="//imayuan.com"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img
                                    alt="码猿网"
                                    className={styles.scratchLogo}
                                    draggable={false}
                                    src={scratchLogo}
                                />
                            </a>
                        </div>
                        <Divider className={classNames(styles.divider)} />
                        <div
                            className={classNames(styles.menuBarItem, styles.hoverable, {
                                [styles.active]: fileMenuOpen
                            })}
                            onMouseUp={onClickFile}
                        >
                            <FormattedMessage
                                defaultMessage="File"
                                description="Text for file dropdown menu"
                                id="gui.menuBar.file"
                            />
                            <MenuBarMenu
                                className={classNames(styles.menuBarMenu)}
                                open={fileMenuOpen}
                                place={isRtl ? 'left' : 'right'}
                                onRequestClose={onRequestCloseFile}
                            >
                                <MenuSection>
                                    <MenuItem
                                        isRtl={isRtl}
                                        onClick={this.handleClickNew}
                                    >
                                        {newProjectMessage}
                                    </MenuItem>
                                </MenuSection>
                                <MenuSection>
                                    <SB3Downloader oader>{(className, downloadProject) => (
                                        <MenuItem
                                            className={className}
                                            onClick={this.handleCloseFileMenuAndThen(downloadProject)}
                                        >
                                            保存到电脑
                                        </MenuItem>
                                    )}</SB3Downloader>
                                    <MYFileUploader onUpdateProjectTitle={onUpdateProjectTitle}>
                                        {(className, renderFileInput, loadProject) => (
                                            <MenuItem
                                                className={className}
                                                onClick={loadProject}
                                            >
                                                导入码猿Scratch作品
                                                {renderFileInput()}
                                            </MenuItem>
                                        )}
                                    </MYFileUploader>
                                    <SBFileUploader onUpdateProjectTitle={onUpdateProjectTitle}>
                                        {(className, renderFileInput, loadProject) => (
                                            <MenuItem
                                                className={className}
                                                onClick={loadProject}
                                            >
                                                导入其他平台作品
                                                {renderFileInput()}
                                            </MenuItem>
                                        )}
                                    </SBFileUploader>
                                </MenuSection>
                            </MenuBarMenu>
                        </div>
                        <div
                            className={classNames(styles.menuBarItem, styles.hoverable, {
                                [styles.active]: editMenuOpen
                            })}
                            onMouseUp={onClickEdit}
                        >
                            <div className={classNames(styles.editMenu)}>
                                <FormattedMessage
                                    defaultMessage="Edit"
                                    description="Text for edit dropdown menu"
                                    id="gui.menuBar.edit"
                                />
                            </div>
                            <MenuBarMenu
                                className={classNames(styles.menuBarMenu)}
                                open={editMenuOpen}
                                place={isRtl ? 'left' : 'right'}
                                onRequestClose={onRequestCloseEdit}
                            >
                                <DeletionRestorer>{(handleRestore, {restorable, deletedItem}) => (
                                    <MenuItem
                                        className={classNames({[styles.disabled]: !restorable})}
                                        onClick={this.handleRestoreOption(handleRestore)}
                                    >
                                        {this.restoreOptionMessage(deletedItem)}
                                    </MenuItem>
                                )}</DeletionRestorer>
                                <MenuSection>
                                    <TurboMode>{(toggleTurboMode, {turboMode}) => (
                                        <MenuItem onClick={toggleTurboMode}>
                                            {turboMode ? (
                                                <FormattedMessage
                                                    defaultMessage="Turn off Turbo Mode"
                                                    description="Menu bar item for turning off turbo mode"
                                                    id="gui.menuBar.turboModeOff"
                                                />
                                            ) : (
                                                <FormattedMessage
                                                    defaultMessage="Turn on Turbo Mode"
                                                    description="Menu bar item for turning on turbo mode"
                                                    id="gui.menuBar.turboModeOn"
                                                />
                                            )}
                                        </MenuItem>
                                    )}</TurboMode>
                                </MenuSection>
                            </MenuBarMenu>
                        </div>
                    </div>
                    <Divider className={classNames(styles.divider)} />
                    <div className={classNames(styles.menuBarItem, styles.growable)}>
                        <ProjectTitleInput
                            className={classNames(styles.titleFieldGrowable)}
                            onUpdateProjectTitle={this.handleProjectTitleChanged}
                        />
                    </div>
                    <ProjectSaveContainer />
                    <div className={classNames(styles.menuBarItem)}>
                        { canRemix ? remixButton : []}
                    </div>
                    <Divider className={classNames(styles.divider)} />
                    {
                        isUploading &&
                        <div className={classNames(styles.menuBarItem)}>
                            <SaveProgress loadStatus={ uploadState } />
                        </div>
                    }
                </div>
                <div className={styles.accountInfoGroup}>
                    { accountState ?
                        <div
                            className={classNames(
                            styles.menuBarItem,
                            styles.hoverable,
                            styles.mystuffButton
                        )}
                            onClick={ onOpenWorkLibrary }
                        >
                            <img
                                className={styles.mystuffIcon}
                                src={mystuffIcon}
                                title="作品库"
                            />
                            <span>我的作品库</span>
                        </div> : null
                    }
                    <Divider className={classNames(styles.divider)} />
                    <AccountContainer />
                </div>
            </Box>
        );
    }
}

MenuBar.propTypes = {
    accountMenuOpen: PropTypes.bool,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canRemix: PropTypes.bool,
    canSave: PropTypes.bool,
    canShare: PropTypes.bool,
    className: PropTypes.string,
    editMenuOpen: PropTypes.bool,
    enableCommunity: PropTypes.bool,
    fileMenuOpen: PropTypes.bool,
    intl: intlShape,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isUploading: PropTypes.bool,
    languageMenuOpen: PropTypes.bool,
    onClickAccount: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickFile: PropTypes.func,
    onClickLanguage: PropTypes.func,
    onClickLogin: PropTypes.func,
    onClickNew: PropTypes.func,
    onClickRemix: PropTypes.func,
    onClickSave: PropTypes.func,
    onClickSaveAsCopy: PropTypes.func,
    onClickUser: PropTypes.func,
    onLogOut: PropTypes.func,
    onOpenProjectModal: PropTypes.func,
    onOpenRegistration: PropTypes.func,
    onOpenTipLibrary: PropTypes.func,
    onOpenWorkLibrary: PropTypes.func,
    onRequestCloseAccount: PropTypes.func,
    onRequestCloseEdit: PropTypes.func,
    onRequestCloseFile: PropTypes.func,
    onRequestCloseLanguage: PropTypes.func,
    onRequestCloseLogin: PropTypes.func,
    onShare: PropTypes.func,
    onToggleLoginOpen: PropTypes.func,
    onUpdateProjectTitle: PropTypes.func,
    renderLogin: PropTypes.func,
    setProject: PropTypes.func,
    uploadState: PropTypes.number
};

MenuBar.defaultProps = {
    onClickUser: () => {},
    onOpenLoginModal: () => {}
};

const mapStateToProps = state => {
    const loadingState = state.scratchGui.projectState.loadingState;
    const projectUploadState = state.scratchGui.projectUploadState;
    const user = state.session && state.session.session && state.session.session.user;
    return {
        accountMenuOpen: accountMenuOpen(state),
        fileMenuOpen: fileMenuOpen(state),
        editMenuOpen: editMenuOpen(state),
        isRtl: state.locales.isRtl,
        isUpdating: getIsUpdating(loadingState),
        isShowingProject: getIsShowingProject(loadingState),
        languageMenuOpen: languageMenuOpen(state),
        loginMenuOpen: loginMenuOpen(state),
        // sessionExists: state.session && typeof state.session.session !== 'undefined',
        // username: user ? user.username : null,
        projectTitle: state.scratchGui.projectTitle,
        isUploading: getIsUploading(projectUploadState),
        uploadState: getUploadState(projectUploadState),
        accountState: state.scratchGui.account.accountState
    };
};

const mapDispatchToProps = dispatch => ({
    onOpenTipLibrary: () => dispatch(openTipsLibrary()),
    onOpenWorkLibrary: ()=> dispatch(openWorkLibrary()),
    onOpenProjectModal: () => dispatch(openProjectModals()),
    onClickAccount: () => dispatch(openAccountMenu()),
    onRequestCloseAccount: () => dispatch(closeAccountMenu()),
    onClickFile: () => dispatch(openFileMenu()),
    onRequestCloseFile: () => dispatch(closeFileMenu()),
    onClickEdit: () => dispatch(openEditMenu()),
    onRequestCloseEdit: () => dispatch(closeEditMenu()),
    onClickLanguage: () => dispatch(openLanguageMenu()),
    onRequestCloseLanguage: () => dispatch(closeLanguageMenu()),
    onClickLogin: () => dispatch(openLoginMenu()),
    onRequestCloseLogin: () => dispatch(closeLoginMenu()),
    onClickNew: needSave => dispatch(requestNewProject(needSave)),
    onClickRemix: () => dispatch(remixProject()),
    onClickSave: () => dispatch(updateProject()),
    onClickSaveAsCopy: () => dispatch(saveProjectAsCopy()),
    onSeeCommunity: () => dispatch(setPlayer(true)),
    setProject: projectInfo => dispatch(setProject(projectInfo))
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuBar));
