exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".confirm_confirm-box_1kPrZ{\n    position: absolute;\n    top: 4rem;\n    left: 0;\n    right: 0;\n    z-index: 1050;\n    text-align: center;\n}\n.confirm_confirm-inner_1jMOV{\n    background: #fff;\n    width: 400px;\n    margin: 0 auto;\n    display: inline-block;\n    text-align: left;\n    position: relative;\n    padding: 1.2rem 1.8rem 1rem;\n    -webkit-box-shadow: 1px 1px 10px 1px #ccc;\n            box-shadow: 1px 1px 10px 1px #ccc;\n    border-radius: .8rem;\n}\n.confirm_close-btn_iUM__{\n    position: absolute;\n    top: .5rem;\n    right: .5rem;\n    display: inline-block;\n    width: 1.4rem;\n    height: 1.4rem;\n    background: #f06160;\n    border-radius: 50%;\n    color: #fff;\n    text-decoration: none;\n    text-align: center;\n    line-height: 1.3rem;\n}\n.confirm_icon-img_1LvgR{\n    width: 1.4rem;\n    float: left;\n}\n.confirm_msgs_3PsRc{\n    color: #666;\n    line-height: 1.4rem;\n    font-size: .8rem;\n    min-height: 3rem;\n    margin-left: 1.8rem;\n}\n.confirm_text-r_3NDq2{\n    text-align: right;\n}\n.confirm_confirm-btn_3tYl7{\n    display: inline-block;\n    padding: .4rem .8rem;\n    color: #fff;\n    border: none;\n    border-radius: .3rem;\n    cursor: pointer;\n    margin-right: .8rem;\n    outline: none;\n}\n.confirm_delete-btn_2zkZp{\n    background: #ccc;\n}\n.confirm_do-btn_2q8D6{\n    background: #4d97ff;\n    margin-right: 0;\n}\n.confirm_confirm-msg_zS_DM{\n    padding: 0 1rem;\n    width: auto;\n    max-width: 400px;\n}\n.confirm_msg-h_36oC_{\n    min-height: auto;\n}\n.confirm_left-no_D4aTt{\n    margin-left: 0;\n}\n", ""]);

// exports
exports.locals = {
	"confirm-box": "confirm_confirm-box_1kPrZ",
	"confirmBox": "confirm_confirm-box_1kPrZ",
	"confirm-inner": "confirm_confirm-inner_1jMOV",
	"confirmInner": "confirm_confirm-inner_1jMOV",
	"close-btn": "confirm_close-btn_iUM__",
	"closeBtn": "confirm_close-btn_iUM__",
	"icon-img": "confirm_icon-img_1LvgR",
	"iconImg": "confirm_icon-img_1LvgR",
	"msgs": "confirm_msgs_3PsRc",
	"text-r": "confirm_text-r_3NDq2",
	"textR": "confirm_text-r_3NDq2",
	"confirm-btn": "confirm_confirm-btn_3tYl7",
	"confirmBtn": "confirm_confirm-btn_3tYl7",
	"delete-btn": "confirm_delete-btn_2zkZp",
	"deleteBtn": "confirm_delete-btn_2zkZp",
	"do-btn": "confirm_do-btn_2q8D6",
	"doBtn": "confirm_do-btn_2q8D6",
	"confirm-msg": "confirm_confirm-msg_zS_DM",
	"confirmMsg": "confirm_confirm-msg_zS_DM",
	"msg-h": "confirm_msg-h_36oC_",
	"msgH": "confirm_msg-h_36oC_",
	"left-no": "confirm_left-no_D4aTt",
	"leftNo": "confirm_left-no_D4aTt"
};