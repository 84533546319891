import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {
    activateTab,
    STUDY_PLAN_TAB_INDEX
} from '../reducers/editor-tab';
import errorBoundaryHOC from '../lib/error-boundary-hoc.jsx';
import PDFReader from '../components/pdf-reader/pdf-reader.jsx';

class StudyPlanTab extends React.Component {
    constructor (props) {
        super(props);
    }


    render () {

        return (
            <PDFReader url={this.props.account.studyPlan}/>
        );
    }
}

StudyPlanTab.propTypes = {

};

const mapStateToProps = state => ({
    account: state.scratchGui.account.account
});

const mapDispatchToProps = dispatch => ({

});

export default errorBoundaryHOC('Study Plan Tab')(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(StudyPlanTab)
);
