import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import WorkLibraryComponent from '../../components/my/work-library.jsx';
import request from '../../lib/request';
import {closeWorkLibrary} from '../../reducers/modals';
import {connect} from 'react-redux';
import {setWork} from '../../reducers/scratch';

class WorkLibrary extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'componentDidMount',
            'handleDelete',
            'getLast',
            'getNext',
            'handleSearch',
            'handleClear',
            'handleKeyDown',
            'handleTagClick'
        ]);
        this.state = {
            works: [],
            tags: [],
            nowPage: 1,
            totalPage: 0,
            numbers: 15,
            searchContent: '',
            searchHis: '',
            searchType: ''
        };
    }

    getResource (nowPage = this.state.nowPage){
        const work = this.props.work;
        const data = {
            'pagination': {
                'number': this.state.numbers,
                'start': (nowPage - 1) * this.state.numbers
            },
            'search': {
                'name': this.state.searchContent || null
            },
            sort: {
                'predicate': 'create_time'
            }
        };
        if (this.state.searchType && this.state.searchType != '0'){
            data.search.type = this.state.searchType;
        }
        request.default_request(request.POST, JSON.stringify(data), `/api/scratch/workslist`, result => {
            if (result.code !== request.NotFindError && result.result) {
                this.setState({
                    works: result.result.records,
                    nowPage: result.result.current,
                    totalPage: result.result.pages
                });
            }
        }, '', 'application/json');
    }

    getType (type){
        request.default_request(request.GET, null, `/api/scratch/type?type=${type}&platFormId=mayuan`, result => {
            if (result.code !== request.NotFindError && result.result) {
                const tags = [];
                result.result.map(tag => {
                    tags.push({id: tag.typeId, title: tag.name});
                });
                this.setState({tags: tags});
            }
        });
    }

    handleTagClick (tag){
        // console.log(tag);
        const tags = this.state.tags;
        let type = 0;
        for (const i in tags){
            if (tags[i].title == tag){
                type = tags[i].id;
            }
        }
        // if(tag == '已发布'){
        //     tag = '1';
        // }else if(tag == '未发布'){
        //     tag = '0';
        // }else if(tag == '所有'){
        //     tag = '';
        // }
        this.setState({searchType: type}, () => {
            this.getResource(1);
        });
    }

    handleSearch (event){
        this.setState({searchContent: event.target.value});
    }

    handleKeyDown (event){
        // console.log(event.keyCode);
        if (event.keyCode == 13){
            this.getResource(1);
        }
        this.setState({searchHis: event.target.value});
    }

    handleClear (){
        this.setState({searchContent: ''}, () => {
            if (this.state.searchHis != ''){
                this.getResource(1);
                this.setState({searchHis: ''});
            }
        });
    }

    getLast (){
        if (this.state.nowPage != 1){
            this.getResource(this.state.nowPage - 1);
        }
        // this.setState({ nowPage: this.state.nowPage - 1 }, () => {
        //     this.getResource();
        // });
    }

    getNext (){
        if (this.state.nowPage != this.state.totalPage && this.state.totalPage != 0){
            this.getResource(this.state.nowPage + 1);
        }
        // this.getResource(this.state.nowPage + 1);
        // this.setState({ nowPage: this.state.nowPage + 1 }, () => {
        //     this.getResource();
        // });
    }

    componentDidMount () {
        this.getType(5); // 获取类别 type, platFormId, userToken
        this.getResource();
        // request.default_request(request.GET, {}, '/internalapi/project/list', result => {
        //     if (typeof result.value !== 'undefined'){
        //         this.setState({workList: result.value});
        //     }
        // });
    }
    handleDelete (){
        this.getResource(1);
    }
    render () {
        return (

            <WorkLibraryComponent
                data={this.state.works}
                tags={this.state.tags}
                id="workLibrary"
                title="我的作品库"
                onDelete={this.handleDelete}
                onRequestClose={this.props.closeWorkLibrary}
                getLast={this.getLast}
                getNext={this.getNext}
                nowPage={this.state.nowPage}
                totalPage={this.state.totalPage}
                handleFilterChange={this.handleSearch}
                handleFilterKeyDown={this.handleKeyDown}
                handleFilterClear={this.handleClear}
                filterQuery={this.state.searchContent}
                handleTagClick={this.handleTagClick}
            />
        );
    }
}

WorkLibrary.propTypes = {
    closeWorkLibrary: PropTypes.func,
    work: PropTypes.object
};

const mapStateToProps = state => ({
    work: state.scratchGui.scratch.work
});
const mapDispatchToProps = dispatch => ({
    closeWorkLibrary: () => {
        dispatch(closeWorkLibrary());
    },
    setWork: work => {
        dispatch(setWork(work));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkLibrary);
