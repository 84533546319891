import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import AccountComponent from './component.jsx';
import {openLoginModals} from '../../reducers/modals';
import {
    openAccountMenu,
    closeAccountMenu,
    accountMenuOpen,
    fileMenuOpen,
    editMenuOpen,
    languageMenuOpen, loginMenuOpen
} from '../../reducers/menus';
import {getIsShowingProject, getIsUpdating} from '../../reducers/project-state';
import { delAccount } from '../../reducers/account';
import Cookies from 'universal-cookie';
import GlobalConfig from '../../config';

class AccountContainer extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'onHandleLogout'
        ]);
        this.state = {
        };
    }

    // 用户退出
    onHandleLogout () {
        // 清除cookie
        // 清除account
        const cookies = new Cookies();
        cookies.remove('token', { path: '/', domain: GlobalConfig.domain});
        console.log('删除scratch.imayuan.com的token')
        cookies.remove('token', { path: '/'});
        console.log('删除scratch.imayuan.com的token完成')
        this.props.delAccount();
        this.props.onCloseAccountMenu();
    }

    render () {
        return (
            <AccountComponent
                {...this.props}
                onHandleLogout = {this.onHandleLogout}
            />);
    }

}

AccountContainer.propTypes = {
    onOpenLoginModal: PropTypes.func
};

const mapStateToProps = state => ({
    accountMenuOpen: accountMenuOpen(state),
    account: state.scratchGui.account.account,
    accountState: state.scratchGui.account.accountState
});

const mapDispatchToProps = dispatch => ({
    onOpenLoginModal: () => dispatch(openLoginModals()),
    onOpenAccountMenu: () => dispatch(openAccountMenu()),
    onCloseAccountMenu: () => dispatch(closeAccountMenu()),
    delAccount: () => dispatch(delAccount())
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountContainer);
