import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {defineMessages, injectIntl, intlShape} from 'react-intl';

import analytics from '../lib/analytics';
import log from '../lib/log';
import JSZip from 'jszip';

import {
    openLoadingProject,
    closeLoadingProject
} from '../reducers/modals';

import CryptoJS from 'crypto-js';
/**
 * Project loader component passes a file input, load handler and props to its child.
 * It expects this child to be a function with the signature
 *     function (renderFileInput, loadProject, props) {}
 * The component can then be used to attach project loading functionality
 * to any other component:
 *
 * <ProjectLoader>{(renderFileInput, loadProject, props) => (
 *     <MyCoolComponent
 *         onClick={loadProject}
 *         {...props}
 *     >
 *         {renderFileInput()}
 *     </MyCoolComponent>
 * )}</ProjectLoader>
 */

const messages = defineMessages({
    loadError: {
        id: 'gui.projectLoader.loadError',
        defaultMessage: 'The project file that was selected failed to load.',
        description: 'An error that displays when a local project file fails to load.'
    }
});
    
/**
 * 解密方法
 * @param {word} word
 */
const Decrypt = function (word) {
    const decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8);
    const decJson = CryptoJS.AES.decrypt(decData, 'myscrch').toString(CryptoJS.enc.Utf8);
    return decJson;
};


class ProjectLoader extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'renderFileInput',
            'setFileInput',
            'handleChange',
            'handleClick'
        ]);
    }
    handleChange (e) {
        // Remove the hash if any (without triggering a hash change event or a reload)
        history.replaceState({}, document.title, '.');
        const thisFileInput = e.target;
        
        if (thisFileInput.files) { // Don't attempt to load if no file was selected
            this.props.openLoadingState();
            const encryptedFile = thisFileInput.files[0];
            const decryptedFile = new JSZip();
            JSZip.loadAsync(encryptedFile).then(zip => {
                const zipSize = Object.keys(zip.files).length;
                zip.forEach((relativePath, file) => {
                    const decodedName = Decrypt(file.name);
                    console.log(decodedName);
                    if (decodedName.indexOf('json') !== -1 || decodedName.indexOf('svg') !== -1){
                        file.async('string')
                            .then(fileContent => {
                                const decodedContent = Decrypt(fileContent);
                                decryptedFile.file(decodedName, decodedContent);
                                if (zipSize === Object.keys(decryptedFile.files).length){
                                    this.loadProject(decryptedFile, thisFileInput, thisFileInput.files[0]);
                                }
                            });
                    } else {
                        file.async('uint8array')
                            .then(fileContent => {
                                decryptedFile.file(decodedName, fileContent);
                                if (zipSize === Object.keys(decryptedFile.files).length){
                                    this.loadProject(decryptedFile, thisFileInput, thisFileInput.files[0]);
                                }
                            });
                    }
                    
                });
            });
        }
    }

    loadProject (zipFile, thisFileInput, originalFile){
        zipFile.generateAsync({type: 'arraybuffer'})
            .then(content => {
                this.props.vm.loadProject(content)
                    .then(() => {
                        analytics.event({
                            category: 'project',
                            action: 'Import Project File',
                            nonInteraction: true
                        });
                        this.props.closeLoadingState();
                        // Reset the file input after project is loaded
                        // This is necessary in case the user wants to reload a project
                        thisFileInput.value = null;
                        // const work = this.props.work;
                        // const newWork = {
                        //     userToken: work.userToken,
                        //     platFormId: work.platFormId,
                        //     userId: work.userId,
                        //     nickname: work.nickname,
                        //     picUrl: work.picUrl
                        // };
                        // this.props.setWork(newWork);
                    })
                    .catch(error => {
                        // 非加密文件
                        this.loadNormalProject(thisFileInput.files[0], thisFileInput);
                    });
            });

    }

    loadNormalProject (file, thisFileInput){
        const reader = new FileReader();
        reader.onload = () => this.props.vm.loadProject(reader.result)
            .then(() => {
                analytics.event({
                    category: 'project',
                    action: 'Import Project File',
                    nonInteraction: true
                });
                this.props.closeLoadingState();
                // Reset the file input after project is loaded
                // This is necessary in case the user wants to reload a project
                thisFileInput.value = null;
                // const work = this.props.work;
                // const newWork = {
                //     userToken: work.userToken,
                //     platFormId: work.platFormId,
                //     userId: work.userId,
                //     nickname: work.nickname,
                //     picUrl: work.picUrl
                // };
                // this.props.setWork(newWork);
            })
            .catch(error => {
                log.warn(error);
                alert(this.props.intl.formatMessage(messages.loadError)); // eslint-disable-line no-alert
                this.props.closeLoadingState();
                // Reset the file input after project is loaded
                // This is necessary in case the user wants to reload a project
                thisFileInput.value = null;
            });
        if (thisFileInput.files) { // Don't attempt to load if no file was selected
            this.props.openLoadingState();
            reader.readAsArrayBuffer(file);
        }
    }

    handleClick () {
        this.fileInput.click();
    }
    setFileInput (input) {
        this.fileInput = input;
    }
    renderFileInput () {
        return (
            <input
                accept=".sb2,.sb3,.my"
                ref={this.setFileInput}
                style={{display: 'none'}}
                type="file"
                onChange={this.handleChange}
            />
        );
    }
    render () {
        const {
            /* eslint-disable no-unused-vars */
            children,
            closeLoadingState,
            openLoadingState,
            vm,
            /* eslint-enable no-unused-vars */
            ...props
        } = this.props;
        return this.props.children(this.renderFileInput, this.handleClick, props);
    }
}

ProjectLoader.propTypes = {
    children: PropTypes.func,
    closeLoadingState: PropTypes.func,
    intl: intlShape.isRequired,
    openLoadingState: PropTypes.func,
    vm: PropTypes.shape({
        loadProject: PropTypes.func
    }),
};

const mapStateToProps = state => ({
    vm: state.scratchGui.vm,
});

const mapDispatchToProps = dispatch => ({
    closeLoadingState: () => dispatch(closeLoadingProject()),
    openLoadingState: () => dispatch(openLoadingProject()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ProjectLoader));
