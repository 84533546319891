import {defineMessages} from 'react-intl';
import sharedMessages from '../shared-messages';

let messages = defineMessages({
    meow: {
        defaultMessage: 'Meow',
        description: 'Name for the meow sound',
        id: 'gui.defaultProject.meow'
    },
    variable: {
        defaultMessage: 'my variable',
        description: 'Name for the default variable',
        id: 'gui.defaultProject.variable'
    }
});

messages = {...messages, ...sharedMessages};

// use the default message if a translation function is not passed
const defaultTranslator = msgObj => msgObj.defaultMessage;

/**
 * Generate a localized version of the default project
 * @param {function} translateFunction a function to use for translating the default names
 * @return {object} the project data json for the default project
 */
const projectData = translateFunction => {
    const translator = translateFunction || defaultTranslator;
    return ({
        targets: [
            {
                isStage: true,
                name: 'Stage',
                variables: {
                    '`jEk@4|i[#Fk?(8x)AV.-my variable': [
                        translator(messages.variable),
                        0
                    ]
                },
                lists: {},
                broadcasts: {},
                blocks: {},
                currentCostume: 0,
                costumes: [
                    {
                        assetId: 'cd21514d0531fdffb22204e0ec5ed84a',
                        name: translator(messages.backdrop, {index: 1}),
                        md5ext: 'cd21514d0531fdffb22204e0ec5ed84a.svg',
                        dataFormat: 'svg',
                        rotationCenterX: 240,
                        rotationCenterY: 180
                    }
                ],
                sounds: [
                    {
                        assetId: '83a9787d4cb6f3b7632b4ddfebf74367',
                        name: translator(messages.pop),
                        dataFormat: 'wav',
                        format: '',
                        rate: 11025,
                        sampleCount: 258,
                        md5ext: '83a9787d4cb6f3b7632b4ddfebf74367.wav'
                    }
                ],
                volume: 100
            },
            {
                isStage: false,
                name: '跑步码猿',
                variables: {},
                lists: {},
                broadcasts: {},
                blocks: {},
                comments: {},
                currentCostume: 0,
                costumes: [
                    {
                        assetId: '3028fdace9a44691ad8362f670466a05',
                        name: '码猿跑步1',
                        bitmapResolution: 1,
                        md5ext: '3028fdace9a44691ad8362f670466a05.svg',
                        dataFormat: 'svg',
                        rotationCenterX: 42.2,
                        rotationCenterY: 54.45
                    },
                    {
                        assetId: 'd5ecff79503c4e5cb2ad105d9ad8d15b',
                        name: '码猿跑步2',
                        bitmapResolution: 1,
                        md5ext: 'd5ecff79503c4e5cb2ad105d9ad8d15b.svg',
                        dataFormat: 'svg',
                        rotationCenterX: 42,
                        rotationCenterY: 54.2
                    },
                    {
                        assetId: '943090f8aeab4a38aa55d61265c8b46d',
                        name: '码猿跑步3',
                        bitmapResolution: 1,
                        md5ext: '943090f8aeab4a38aa55d61265c8b46d.svg',
                        dataFormat: 'svg',
                        rotationCenterX: 42,
                        rotationCenterY: 54.2
                    },
                    {
                        assetId: 'dbf5b2ef491a4452b049d080a9ee16f8',
                        name: '码猿跑步4',
                        bitmapResolution: 1,
                        md5ext: 'dbf5b2ef491a4452b049d080a9ee16f8.svg',
                        dataFormat: 'svg',
                        rotationCenterX: 42,
                        rotationCenterY: 54.25
                    },
                    {
                        assetId: '10ec772bc7924b77a1cbba853e29fad3',
                        name: '码猿跑步5',
                        bitmapResolution: 1,
                        md5ext: '10ec772bc7924b77a1cbba853e29fad3.svg',
                        dataFormat: 'svg',
                        rotationCenterX: 42.15,
                        rotationCenterY: 54.25
                    },
                    {
                        assetId: '434acf82fd38482e9dc1e4c68313eb54',
                        name: '码猿跑步6',
                        bitmapResolution: 1,
                        md5ext: '434acf82fd38482e9dc1e4c68313eb54.svg',
                        dataFormat: 'svg',
                        rotationCenterX: 42.2,
                        rotationCenterY: 54.25
                    },
                    {
                        assetId: '11171f34727e4ef4805203f9f46177f9',
                        name: '码猿跑步7',
                        bitmapResolution: 1,
                        md5ext: '11171f34727e4ef4805203f9f46177f9.svg',
                        dataFormat: 'svg',
                        rotationCenterX: 42,
                        rotationCenterY: 54.05
                    },
                    {
                        assetId: '64f5ad1ddf3b4aaf91d9d080b7cf0f15',
                        name: '码猿跑步8',
                        bitmapResolution: 1,
                        md5ext: '64f5ad1ddf3b4aaf91d9d080b7cf0f15.svg',
                        dataFormat: 'svg',
                        rotationCenterX: 42,
                        rotationCenterY: 54.05
                    },
                    {
                        assetId: '571c22e161954bbfb17113e8a07a59e6',
                        name: '码猿跑步9',
                        bitmapResolution: 1,
                        md5ext: '571c22e161954bbfb17113e8a07a59e6.svg',
                        dataFormat: 'svg',
                        rotationCenterX: 42,
                        rotationCenterY: 54
                    },
                    {
                        assetId: '259f1459d4974892b0343375ff5ef6c8',
                        name: '码猿跑步10',
                        bitmapResolution: 1,
                        md5ext: '259f1459d4974892b0343375ff5ef6c8.svg',
                        dataFormat: 'svg',
                        rotationCenterX: 42.1,
                        rotationCenterY: 54.3
                    }
                ],
                sounds: [],
                volume: 100,
                layerOrder: 1,
                visible: true,
                x: 0,
                y: -4.000000000000014,
                size: 100,
                direction: 90,
                draggable: false,
                rotationStyle: "don't rotate"
            }
        ],
        meta: {
            semver: '3.0.0',
            vm: '0.1.0',
            agent: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/65.0.3325.181 Safari/537.36' // eslint-disable-line max-len
        }
    });
};


export default projectData;
