exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".readonly_modal-visible_2ztD9{\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: transparent;\n    z-index: 999;\n}\n.readonly_modal-hidden_2RIQO{\n    display: none;\n}\n", ""]);

// exports
exports.locals = {
	"modal-visible": "readonly_modal-visible_2ztD9",
	"modalVisible": "readonly_modal-visible_2ztD9",
	"modal-hidden": "readonly_modal-hidden_2RIQO",
	"modalHidden": "readonly_modal-hidden_2RIQO"
};