import PropTypes from 'prop-types';
import React from 'react';
import styles from './readonly.css';
import classNames from 'classnames';

const ReadonlyComponent = props => {
    return (
        <div className={classNames(styles.modalVisible)}>

        </div>
    );

};

export default ReadonlyComponent;
