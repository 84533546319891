import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import PublishComponent from './component.jsx';
import {getQueryString} from '../../lib/request';
import request from '../../lib/request';
import {closePublishModal} from '../../reducers/modals';
import VM from 'scratch-vm';
import {connect} from 'react-redux';
import {setProject} from '../../reducers/project-info';
import {setConfirm, setConfirmBack} from '../../reducers/confirm';
import fireKeyEvent from '../../lib/key-map';
import {version} from '../../lib/config';
import {saveProject, getProjectState} from '../../lib/service/project-api';

class Publish extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'componentDidMount',
            'handleOnSave',
            'handleChange',
            'onHandleCancel',
            'getType',
            'handleTagClick',
            'handleChangeName',
            'handleChangeDesc',
            'handleAnon'
        ]);
        this.state = {
            id: '',
            workName: '',
            describe: '',
            selectedTag: '',
            shotSrc: '',
            coverSrc: '',
            isShow: false,
            tags: [],
            iAnon: false
        };
    }

    handleAnon() {
        this.setState({iAnon: !this.state.iAnon})
    }

    getType(type) {
        request.default_request(request.GET, null, `/api/scratch/type?type=${type}`, result => {
            if (result.code !== request.NotFindError && result.result) {
                let tags = [];
                result.result.map(tag => {
                    tags.push({id: tag.typeId, title: tag.name});
                });
                this.setState({tags: tags});
            }
        });
    }

    handleChangeName(event) {
        this.setState({workName: event.target.value});
    }

    handleChangeDesc(event) {
        this.setState({describe: event.target.value});
    }

    handleTagClick(tag) {
        let tags = this.state.tags;
        let type;
        for (let i in tags) {
            if (tags[i].title == tag) {
                type = tags[i].id;
            }
        }
        this.setState({selectedTag: type});
    }

    componentDidMount() {
        this.getType(5);    // 获取作品分类
        this.setState({workName: this.props.projectInfo.name || ''});
        const shotBtn = document.getElementById('shotBtn');
        fireKeyEvent(shotBtn, 'keydown', 16);
        this.state.coverSrc = sessionStorage.getItem('coverImg');
    }

    handleOnSave() {
        if (!this.state.workName) {
            alert("请先为作品命名");
            return;
        }
        this.setState({iDisable: true});
        let projectInfo = JSON.parse(JSON.stringify(this.props.projectInfo));
        let saveData = {
            'id': projectInfo.id || '',
            'name': this.state.workName,
            'remarks': this.state.describe,
            'type': this.state.selectedTag,
            'cover': sessionStorage.getItem('coverImg'),
            'isRelease': 1,
            'isAnon': this.state.iAnon - 0,
            'version': version,
            'nickname': this.props.account.nickname,
            "userId": this.props.account.id
        };

        this.props.vm.saveProjectSb3().then(content => {
            saveData.file = content;
            // 可控制是否上传
            saveData.cover = sessionStorage.getItem('coverImg');
            saveProject(saveData)
                .then(result => {
                    if (result.code == 0 && result.result) {
                        // 上传成功
                        let projectData = {
                            ...this.props.projectInfo,
                            ...result.result
                        };
                        this.props.setProject(projectData);
                        let msg = {
                            type: 1,
                            message: '发布成功',
                            status: 1,
                            timeout: 3000,
                            show: true
                        };
                        this.props.setConfirm(msg);
                    } else {
                        let msg = {
                            type: 1,
                            message: '发布失败',
                            status: 2,
                            timeout: 3000,
                            show: true
                        };
                        this.props.setConfirm(msg);
                    }
                    this.onHandleCancel();
                })
        });
    }

    handleChange(event) {
        this.setState({workName: event.target.value});
    }

    onHandleCancel() {
        this.props.closePublishModal();
        this.setState({iDisable: false});
    }

    render() {
        /* eslint-disable no-unused-vars */
        return (
            <PublishComponent
                handleCancel={this.onHandleCancel}
                label={'作品名称'}
                placeholder={'输入作品名称'}
                desPlaceholder={'描述作品的操作说明'}
                coverSrc={this.state.coverSrc}
                title="保存作品"
                workName={this.state.workName}
                describe={this.state.describe}
                tags={this.state.tags}
                handleTagClick={this.handleTagClick}
                onChangeName={this.handleChangeName}
                onChangeDesc={this.handleChangeDesc}
                handleOnSave={this.handleOnSave}
                handleAnon={this.handleAnon}
                iDisable={this.state.iDisable}
            />);
    }
}

Publish.propTypes = {
    closeSaveModal: PropTypes.func,
    vm: PropTypes.shape({
        saveProjectSb3: PropTypes.func
    })
};
const mapStateToProps = state => ({
    vm: state.scratchGui.vm,
    projectInfo: state.scratchGui.projectInfo,
    account: state.scratchGui.account.account,
});

const mapDispatchToProps = dispatch => ({
    setProject: projectInfo => {
        dispatch(setProject(projectInfo));
    },
    closePublishModal: () => {
        dispatch(closePublishModal());
    },
    setConfirm: (confirm) => {
        dispatch(setConfirm(confirm));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Publish);
