import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import ProjectSaveComponent from './component.jsx';
import {setProject} from '../../reducers/project-info';
import {openPublishModals, openLoginModals} from '../../reducers/modals';
import {version} from '../../lib/config';
import {saveProject, getProjectState} from '../../lib/service/project-api';
import {onUploadStateChanged, UploadingStates} from '../../reducers/project-upload_state';
import {setConfirm, setConfirmBack} from '../../reducers/confirm';
import {resolve} from 'path';
import {rejects} from 'assert';
/**
 * 用于向服务器保存作品
 */

class ProjectSaveContainer extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'onHandleSave',
            'onHandleSaveCopy',
            'uploadProject',
            'saveCover',
            'onHandlePublish',
            'checkAccount',
            'saveToShenben',
            'handleAutoSave',
            'clearAutoSaveTimer',
            'setAutoSaveTimer',
            'autoSave',
            'loadBack'
        ]);
        this.state = {
            iAutoSave: true,
            iUploading: false
        };
    }

    // 作品保存的提示信息
    loadBack(iFlag, message) {
        const msg = {
            type: 1,
            message: message ? message : iFlag ? '上传成功' : '上传失败,请稍后再试',
            status: iFlag ? 1 : 2,
            timeout: 1500,
            show: true
        };
        this.props.setConfirm(msg);
    }

    componentDidMount() {
        let autoSave = window.sessionStorage.getItem('autoSave');
        if (autoSave === 'false') {
            this.setState({
                iAutoSave: false
            });
            return false;
        }
        if (autoSave === 'true' || this.state.iAutoSave) {
            this.setAutoSaveTimer();
        }
    }


    componentWillUnmount() {
        if (this.autoSaveTimer) {
            clearTimeout(this.autoSaveTimer);
        }
    }

    // 检查用户
    checkAccount() {
        if (!this.props.accountState) {
            this.props.onOpenLoginModal();
            return false;
        }
        return true;
    }

    // 保存
    onHandleSave(callback) {
        // 判断用户是否登录
        if (!this.checkAccount()) {
            return false;
        }
        if (this.state.iUploading) {
            return false;
        }
        if (this.props.projectFilename && this.props.projectFilename.length > 32) {
            const msg = {
                type: 1,
                message: '项目名称过长,至多不超过32字',
                status: 2,
                timeout: 1500,
                show: true
            };
            this.props.setConfirm(msg);
            return false;
        }
        const data = {
            id: this.props.project.id,
            name: this.props.projectFilename,
            version: this.props.project.version
        };
        this.props.onUploadStateChanged(UploadingStates.STARTED_UPLOAD_TO_SERVER);
        this.uploadProject(data, id => {
            if (callback) callback(id);
        });
    }

    // 另存
    onHandleSaveCopy() {
        if (!this.checkAccount()) {
            return false;
        }
        const data = {
            name: this.props.projectFilename,
            version: version
        };
        this.uploadProject(data, (id)=> {
            const currentUrl = window.location.href;
            const prefix = currentUrl.split('?')[0];
            window.location.href = `${prefix}?id=${id}`;
        });
    }

    // 作品上传
    uploadProject(data, callback) {
        if (this.state.iUploading) {
            return false;
        }
        this.setState({
            iUploading: true
        });
        this.saveCover();
        this.props.saveProjectSb3().then(content => {
            this.props.onUploadStateChanged(UploadingStates.PREPARING_UPLOAD_TO_SERVER);
            data.file = content;
            // 可控制是否上传
            data.cover = sessionStorage.getItem('coverImg');
            return data;
        }).then(data => saveProject(data))
            .then(res => {
                if (res.code !== 0) {
                    this.loadBack(false, res.msg);
                    this.props.onUploadStateChanged(UploadingStates.NOT_UPLOADING);
                    throw new Error(res.msg);
                }
                this.props.onUpdateProject({
                    id: res.result.id
                });
                this.props.onUploadStateChanged(UploadingStates.UPLOADING_T0_SERVER);
                return res.result.id;
            })
            .then(id => {
                const timer = setInterval(() => {
                    getProjectState(id).then(
                        result => {
                            const status = result.msg;
                            if (status == '0') return;
                            clearInterval(timer);
                            this.setState({
                                iUploading: false
                            });
                            if (status == '1') {
                                // 成功
                                this.props.onUploadStateChanged(UploadingStates.DONE_UPLOAD_TO_SERVER);
                                setTimeout(() => {
                                    this.props.onUploadStateChanged(UploadingStates.NOT_UPLOADING);

                                }, 200);
                                if (callback) {
                                    callback(id)
                                }
                            } else if (status == '-1') {
                                // 失败
                                this.props.onUploadStateChanged(UploadingStates.ERROR_UPLOAD_TO_SERVER);
                                setTimeout(() => this.props.onUploadStateChanged(UploadingStates.NOT_UPLOADING), 200);
                            }
                        }
                    ).catch(err => {
                        this.setState({
                            iUploading: false
                        });
                    });
                }, 1000);
            }).catch(err => {
                console.error(err);
                this.setState({
                    iUploading: false
                });
            });
    }

    // 保存封面
    saveCover() {
        if (!sessionStorage.getItem('coverImg')) {
            const shotBtn = document.getElementById('shotBtn');
            shotBtn.click();
        }
    }

    // 发布
    onHandlePublish() {
        if (!this.props.accountState) {
            this.props.onOpenLoginModal();
        } else {
            this.props.onOpenPublishModal();
        }
    }

    // 保存到shenben
    saveToShenben(projectId) {
        if (!sessionStorage.getItem('st')) {
            // 如果没有st标识,则不需要保存到shenben
            return;
        }
        const chapterId = sessionStorage.getItem('chapterId');
        let userInfo = sessionStorage.getItem('shenbenInfo');
        let username = '';
        if (userInfo) {
            userInfo = JSON.parse(userInfo);
            username = userInfo.userMsg.userInfo.username;
        }
        if (!chapterId || !username) {
            return false;
        }
        const saveData = {
            projectId: projectId,
            chapterId: chapterId,
            username: username
        };
        request.default_request(request.POST, saveData, '/api/shenben/saveProjectToShenben', res => {
            console.log(res);
        });
    }

    // 是否自动保存
    handleAutoSave(event) {
        let isChecked = event.target.checked;
        this.setState({
            iAutoSave: isChecked
        });
        window.sessionStorage.setItem('autoSave', isChecked);
        // 开启或关闭定时器
        if (isChecked) {
            // 开启定时器
            this.setAutoSaveTimer();
        } else {
            this.clearAutoSaveTimer();
        }
    }

    // 去掉自动保存
    clearAutoSaveTimer() {
        if (this.autoSaveTimer) {
            clearTimeout(this.autoSaveTimer);
        }
    }

    // 设置自动保存定时
    setAutoSaveTimer() {
        this.clearAutoSaveTimer();
        this.autoSaveTimer = setTimeout(()=> {
            // 判断用户是否登录
            // 如果有项目,判断用户和项目创建者是否一致
            let {
                accountState,
                project,
                account
            } = this.props;
            if (!accountState || project.id && project.createUserId !== account.id) {
                return false;
            }
            this.autoSave();
        }, 10 * 60 * 1000);
    }


    // 自动保存
    autoSave() {
        this.onHandleSave(result => {
            if (result) {
                this.setAutoSaveTimer();
            }
        })
    };

    render() {
        return (
            <ProjectSaveComponent
                handleSave={this.onHandleSave.bind(this, null)}
                handleSaveCopy={this.onHandleSaveCopy}
                handleSaveCover={this.saveCover}
                handlePublish={this.onHandlePublish}
                handleAutoSave={this.handleAutoSave}
                iAutoSave={this.state.iAutoSave}
                {...this.props}
            />);
    }

}

const getProjectFilename = curTitle => {
    const date = new Date();
    const timestamp = `${date.toLocaleDateString()}-${date.toLocaleTimeString()}`;
    const filename = curTitle ? `${curTitle}` : `未命名-${timestamp}`;
    return `${filename.substring(0, 100)}`;
};

ProjectSaveContainer.propTypes = {
    onClickSaveAsCopy: PropTypes.func,
    onOpenPublishModal: PropTypes.func,
    onUpdateProject: PropTypes.func,
    onUploadStateChanged: PropTypes.func,
    project: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
    }),
    projectFilename: PropTypes.string,
    saveProjectSb3: PropTypes.func
};

const mapStateToProps = state => ({
    saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
    project: state.scratchGui.projectInfo,
    projectFilename: getProjectFilename(state.scratchGui.projectInfo.name),
    accountState: state.scratchGui.account.accountState,
    account: state.scratchGui.account.account
});

const mapDispatchToProps = dispatch => ({
    onOpenPublishModal: () => dispatch(openPublishModals()),
    onUpdateProject: project => dispatch(setProject(project)),
    onUploadStateChanged: uploadState => dispatch(onUploadStateChanged(uploadState)),
    onOpenLoginModal: () => dispatch(openLoginModals()),
    setConfirm: confirm => {
        dispatch(setConfirm(confirm));
    },
    setConfirmBack: () => {
        dispatch(setConfirmBack());
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectSaveContainer);
