import AudioEngine from 'scratch-audio';
import PropTypes from 'prop-types';
import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import ReactModal from 'react-modal';
import request, {getTargetId, getQueryString} from '../lib/request';
import VM from 'scratch-vm';
import {injectIntl, intlShape} from 'react-intl';

import ErrorBoundaryHOC from '../lib/error-boundary-hoc.jsx';
import {
    getIsError,
    getIsShowingProject
} from '../reducers/project-state';
import {setProject} from '../reducers/project-info';
import bindAll from 'lodash.bindall';
import {
    activateTab,
    BLOCKS_TAB_INDEX,
    COSTUMES_TAB_INDEX,
    SOUNDS_TAB_INDEX,
    STUDY_PLAN_TAB_INDEX
} from '../reducers/editor-tab';

import {
    closeCostumeLibrary,
    closeBackdropLibrary,
    closeTelemetryModal,
    openExtensionLibrary,
} from '../reducers/modals';
import FontLoaderHOC from '../lib/hoc/font-loader-hoc.jsx';
import LocalizationHOC from '../lib/localization-hoc.jsx';
import ProjectFetcherHOC from '../lib/hoc/project-fetcher-hoc.jsx';
import ProjectSaverHOC from '../lib/hoc/project-saver-hoc.jsx';
import QueryParserHOC from '../lib/query-parser-hoc.jsx';
import storage from '../lib/storage';
import vmListenerHOC from '../lib/hoc/vm-listener-hoc.jsx';
import vmManagerHOC from '../lib/hoc/vm-manager-hoc.jsx';
import cloudManagerHOC from '../lib/cloud-manager-hoc.jsx';

import GUIComponent from '../components/gui/gui.jsx';
import Cookies from 'universal-cookie';
import {setIsScratchDesktop} from '../lib/isScratchDesktop.js';
import { setAccount } from '../reducers/account';

const cookies = new Cookies();

class GUI extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'getUserInfo',
            'getWork'
        ]);
        this.state = {
            loading: !props.vm.initialized,
            loadingError: false,
            errorMessage: '',
            studyPlanVisible: false
        };
    }

    // 获取用户信息
    getUserInfo () {
        request.default_request(request.GET, null, `/api/getUserInfo`, result => {
            if (!result) return;
            result = JSON.parse(result);
            if (result.code == 0){
                this.props.setAccount(result.result);
            }
        }, '//imayuan.com');
    }

    // 获取作品
    getWork (workId) {
        request.default_request(request.GET, null, `/api/scratch/getWork?scratchId=${workId}&deviceIdentify=1`, result => {
            let projectData = {};
            if (result.code !== request.NotFindError && result.result){
                const res = result.result;
                projectData = {...res};
            }
            this.props.setProject(projectData);
        });
    }

    componentDidMount () {
        setIsScratchDesktop(this.props.isScratchDesktop);
        const id = getQueryString('id');
        const st = getQueryString('st') || sessionStorage.getItem('st');
        const platFormId = getQueryString('platFormId') || 'mayuan';
        const userToken = cookies.get('token');

        if (st){
            this.setState({
                studyPlanVisible: true
            });
            sessionStorage.setItem('st', st);
            // shenben平台登录
            request.default_request(request.GET, null, `/api/shenben/tryLogin?token=${st}`, result => {
                let info = null;
                if (result.code == 0){
                    // 设置token
                    const d = new Date();
                    d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
                    // todo 修改domain到imayuan下
                    // cookies.set('token', result.result.token, {expires: d, path: '/'});
                    cookies.set('token', result.result.token, {expires: d, path: '/', domain: '.imayuan.com'});
                    sessionStorage.setItem('shenbenInfo', JSON.stringify(result.result));
                    sessionStorage.setItem('chapterId', getQueryString('chapterId'));
                    info = result.result;

                } else {
                    // st过期了, 页面刷新
                    const shenbenInfo = sessionStorage.getItem('shenbenInfo');
                    info = shenbenInfo ? JSON.parse(shenbenInfo) : null;
                    console.log(result);
                }
                if (info){
                    this.props.setAccount({
                        studyPlan: info.studyPlan, 
                        ...info.userMsg.userInfo
                    });
                }
            });
        } else {
            // imayuan平台登录
            if (userToken){
                this.getUserInfo();
            }
        }
        if (id){
            this.getWork(id);
        }
        window.sessionStorage.setItem('coverImg', '');
        this.setReduxTitle(this.props.projectTitle);
        this.props.onStorageInit(storage);
        this.props.onVmInit(this.props.vm);
    }
    componentDidUpdate (prevProps) {
        if (this.props.projectId !== prevProps.projectId && this.props.projectId !== null) {
            this.props.onUpdateProjectId(this.props.projectId);
        }
        if (this.props.projectTitle !== prevProps.projectTitle) {
            this.setReduxTitle(this.props.projectTitle);
        }
        if (this.props.isShowingProject && !prevProps.isShowingProject) {
            // this only notifies container when a project changes from not yet loaded to loaded
            // At this time the project view in www doesn't need to know when a project is unloaded
            this.props.onProjectLoaded();
        }
    }


    setReduxTitle (newTitle) {
        if (newTitle === null || typeof newTitle === 'undefined') {
            this.props.onUpdateReduxProjectTitle(
                {name: ''}
            );
        } else {
            this.props.onUpdateReduxProjectTitle({name: newTitle});
        }
    }
    render () {
        if (this.props.isError) {
            throw new Error(
                `Error in Scratch GUI [location=${window.location}]: ${this.props.error}`);
        }
        const {
            /* eslint-disable no-unused-vars */
            assetHost,
            cloudHost,
            error,
            isError,
            isScratchDesktop,
            isShowingProject,
            onProjectLoaded,
            onStorageInit,
            onUpdateProjectId,
            onUpdateReduxProjectTitle,
            onVmInit,
            projectHost,
            projectId,
            projectTitle,
            /* eslint-enable no-unused-vars */
            children,
            fetchingProject,
            isLoading,
            loadingStateVisible,
            projectData, // eslint-disable-line no-unused-vars
            vm,
            ...componentProps
        } = this.props;

        return (
            <GUIComponent
                loading={fetchingProject || isLoading || loadingStateVisible}
                studyPlanVisible={this.state.studyPlanVisible}
                vm={vm}
                {...componentProps}
            >
                {children}
            </GUIComponent>
        );
    }
}

GUI.propTypes = {
    assetHost: PropTypes.string,
    children: PropTypes.node,
    cloudHost: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    fetchingProject: PropTypes.bool,
    intl: intlShape,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    isScratchDesktop: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    loadingStateVisible: PropTypes.bool,
    loginModalVisible: PropTypes.bool,
    onProjectLoaded: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onStorageInit: PropTypes.func,
    onUpdateProjectId: PropTypes.func,
    onUpdateProjectTitle: PropTypes.func,
    onUpdateReduxProjectTitle: PropTypes.func,
    onVmInit: PropTypes.func,
    previewInfoVisible: PropTypes.bool,
    projectData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    projectHost: PropTypes.string,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    projectTitle: PropTypes.string,
    saveModalVisible: PropTypes.bool,
    telemetryModalVisible: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired
};

GUI.defaultProps = {
    isScratchDesktop: false,
    onStorageInit: storageInstance => storageInstance.addOfficialScratchWebStores(),
    onProjectLoaded: () => {},
    onUpdateProjectId: () => {},
    onVmInit: (/* vm */) => {}
};

const mapStateToProps = state => {
    const loadingState = state.scratchGui.projectState.loadingState;
    return {
        activeTabIndex: state.scratchGui.editorTab.activeTabIndex,
        alertsVisible: state.scratchGui.alerts.visible,
        backdropLibraryVisible: state.scratchGui.modals.backdropLibrary,
        blocksTabVisible: state.scratchGui.editorTab.activeTabIndex === BLOCKS_TAB_INDEX,
        cardsVisible: state.scratchGui.cards.visible,
        connectionModalVisible: state.scratchGui.modals.connectionModal,
        costumeLibraryVisible: state.scratchGui.modals.costumeLibrary,
        costumesTabVisible: state.scratchGui.editorTab.activeTabIndex === COSTUMES_TAB_INDEX,
        error: state.scratchGui.projectState.error,
        isError: getIsError(loadingState),
        isFullScreen: state.scratchGui.mode.isFullScreen,
        isPlayerOnly: state.scratchGui.mode.isPlayerOnly,
        isRtl: state.locales.isRtl,
        isShowingProject: getIsShowingProject(loadingState),
        loadingStateVisible: state.scratchGui.modals.loadingProject,
        previewInfoVisible: state.scratchGui.modals.previewInfo && !ownProps.hideIntro,
        loginModalVisible: state.scratchGui.modals.loginModal,
        publishModalVisible: state.scratchGui.modals.publishModal,
        projectModalVisible: state.scratchGui.modals.projectModal,
        projectId: state.scratchGui.projectState.projectId,
        soundsTabVisible: state.scratchGui.editorTab.activeTabIndex === SOUNDS_TAB_INDEX,
        targetIsStage: (
            state.scratchGui.targets.stage &&
            state.scratchGui.targets.stage.id === state.scratchGui.targets.editingTarget
        ),
        telemetryModalVisible: state.scratchGui.modals.telemetryModal,
        tipsLibraryVisible: state.scratchGui.modals.tipsLibrary,
        vm: state.scratchGui.vm,
        saveModalVisible: state.scratchGui.modals.saveModal,
        workLibraryVisible: state.scratchGui.modals.workLibrary,
        readonlyVisible: state.scratchGui.modals.readonlyModal,
        studyPlanTabVisible: state.scratchGui.editorTab.activeTabIndex === STUDY_PLAN_TAB_INDEX,
        confirmVisibe: state.scratchGui.confirm.confirmConf.show,
        confirmType: state.scratchGui.confirm.confirmConf.type,
        confirmMessage: state.scratchGui.confirm.confirmConf.message,
        confirmStatus: state.scratchGui.confirm.confirmConf.status,
        confirmTimeout: state.scratchGui.confirm.confirmConf.timeout,
        confirmHandleSure: state.scratchGui.confirm.confirmConf.sure,
        project: state.scratchGui.projectInfo,
        account: state.scratchGui.account.account
    };
};

const mapDispatchToProps = dispatch => ({
    setProject: projectInfo => {dispatch(setProject(projectInfo));},
    onExtensionButtonClick: () => dispatch(openExtensionLibrary()),
    onActivateTab: tab => dispatch(activateTab(tab)),
    onActivateCostumesTab: () => dispatch(activateTab(COSTUMES_TAB_INDEX)),
    onActivateSoundsTab: () => dispatch(activateTab(SOUNDS_TAB_INDEX)),
    onActivateStudyPlanTab: () => dispatch(activateTab(STUDY_PLAN_TAB_INDEX)),
    onRequestCloseBackdropLibrary: () => dispatch(closeBackdropLibrary()),
    onRequestCloseCostumeLibrary: () => dispatch(closeCostumeLibrary()),
    onRequestCloseTelemetryModal: () => dispatch(closeTelemetryModal()),
    onUpdateReduxProjectTitle: project => dispatch(setProject(project)),
    setAccount: account => dispatch(setAccount(account))
});

const ConnectedGUI = injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps,
)(GUI));

// note that redux's 'compose' function is just being used as a general utility to make
// the hierarchy of HOC constructor calls clearer here; it has nothing to do with redux's
// ability to compose reducers.
const WrappedGui = compose(
    LocalizationHOC,
    ErrorBoundaryHOC('Top Level App'),
    FontLoaderHOC,
    QueryParserHOC,
    ProjectFetcherHOC,
    ProjectSaverHOC,
    vmListenerHOC,
    vmManagerHOC,
    cloudManagerHOC
)(ConnectedGUI);

WrappedGui.setAppElement = ReactModal.setAppElement;
export default WrappedGui;
