import React, { PureComponent } from 'react';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import Box from '../box/box.jsx';
import bindAll from 'lodash.bindall';
import styles from './pdf-reader.css';

class PDFReader extends PureComponent {
    constructor (props) {
        super(props);

        this.readAreaRef = null;

        bindAll(this, [
            'onDocumentLoad',
            'handleResize',
            'setReadAreaRef'
        ]);

        this.state = {
            numPages: null,
            pageNumber: 1,
            url: this.props.url,
            readerWidth: 0
        };
    }

    static getDerivedStateFromProps(nextProps, preState) {
        if (nextProps.url === preState.url) {
            return null;
        }
        return {
            url: nextProps.url
        };
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize',  this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize',  this.handleResize);
    }

    setReadAreaRef(element){
        this.readAreaRef = element;
    };

    handleResize() {
        let width = this.readAreaRef.offsetWidth;
        if(width !== this.state.readerWidth){
            this.setState({
                readerWidth: width
            })   
        }
    }

    onDocumentLoad({ numPages }){
        this.setState({ numPages });
    };

    render() {
        return (
            <Box className={styles.wrapper}>
                <div className={styles.reader} ref={this.setReadAreaRef}>
                    <Document
                        file= {this.state.url}
                        onLoadSuccess={this.onDocumentLoad}
                        className={styles.document}
                    >
                        {
                            Array.from(
                                new Array(this.state.numPages),
                                (el, index) => (
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        width={this.state.readerWidth}
                                    />
                                ),
                            )
                        }
                    </Document>
                </div>
            </Box>
        )
    }
}

export default PDFReader;
