import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import CryptoJS from 'crypto-js';
import JSZip from 'jszip';

 // 加密方法
const Encrypt = function (word) {
    // 加密数据
    const encJson = CryptoJS.AES.encrypt(word, 'myscrch').toString();
    // 对加密数据进行base64处理, 原理：就是先将字符串转换为utf8字符数组，再转换为base64数据
    const encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
    return encData;
};
class SB3Downloader extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'downloadProject'
        ]);
    }
    downloadProject () {
        this.props.saveProjectSb3().then(content => {
            const encodedZip = new JSZip();
            JSZip.loadAsync(content).then(zip => {
                const zipSize = Object.keys(zip.files).length;
                zip.forEach((relativePath, file) => {
                    const encodedName = Encrypt(file.name);
                    if (file.name.indexOf('json') !== -1 || file.name.indexOf('svg') !== -1){
                        file.async('string')
                            .then(fileContent => {
                                const encodedContent = Encrypt(fileContent);
                                encodedZip.file(encodedName, encodedContent);
                                if (zipSize === Object.keys(encodedZip.files).length){
                                    this.saveZipFile(encodedZip);
                                }
                            });
                    } else {
                        file.async('uint8array')
                            .then(fileContent => {
                                encodedZip.file(encodedName, fileContent);
                                if (zipSize === Object.keys(encodedZip.files).length){
                                    this.saveZipFile(encodedZip);
                                }
                            });
                    }
                    
                });
            });
        });
    }

    saveZipFile (encodedZip) {
        encodedZip.generateAsync({
            type: 'blob',
            mimeType: 'application/zip',
            compression: 'DEFLATE'
        }
        ).then(zipContent => {
            const {projectFilename} = this.props;
            const saveLink = document.createElement('a');
            document.body.appendChild(saveLink);

            // Use special ms version if available to get it working on Edge.
            if (navigator.msSaveOrOpenBlob) {
                navigator.msSaveOrOpenBlob(zipContent, projectFilename);
                return;
            }

            const url = window.URL.createObjectURL(zipContent);
            saveLink.href = url;
            saveLink.download = projectFilename;
            saveLink.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(saveLink);
            if (this.props.onSaveFinished) {
                this.props.onSaveFinished();
            }
        });
        
    }
    render () {
        const {
            children
        } = this.props;
        return children(
            this.props.className,
            this.downloadProject
        );
    }
}

const getProjectFilename = curTitle => {
    const date = new Date();
    const timestamp = `${date.toLocaleDateString()}-${date.toLocaleTimeString()}`;
    const filename = curTitle ? `${curTitle}.my` : `未命名-${timestamp}.my`;
    return `${filename.substring(0, 100)}`;
};

SB3Downloader.propTypes = {
    children: PropTypes.func,
    className: PropTypes.string,
    onSaveFinished: PropTypes.func,
    projectFilename: PropTypes.string,
    saveProjectSb3: PropTypes.func
};
SB3Downloader.defaultProps = {
    className: ''
};

const mapStateToProps = state => ({
    saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
    projectFilename: getProjectFilename(state.scratchGui.projectInfo.name)
});

export default connect(
    mapStateToProps,
    () => ({}) // omit dispatch prop
)(SB3Downloader);
